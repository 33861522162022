import Login from '@/components/Login/Login';
import Kakao from '@/components/Login/Kakao';

let loginRouter = {
    path: '/login',
    name: 'LoginRouter',
    component: Login,
    children: [
        {
            path: 'kakao',
            component: Kakao,
        },
    ]
};

export default loginRouter;