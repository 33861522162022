<template>
    <div id='login-vue'>
        <header style="margin-bottom: 20px; text-align: center;">
            <p>과거 주문내역 조회</p>
        </header>
        <main>
            <div class="inPanel" style="text-align: center;">
                <router-view v-on:login="() => { loading = true; }"></router-view>
                <template v-if="loading">
                    
                </template>
                <template v-else>
                    <img id="letterImage"
                        src="../icon/img_letterCover.png">

                    <div id="inText" style="margin-bottom: 40px">
                        <p>개인정보 보호를 위해</p>
                        <p>구매자님 로그인이 필요해요.</p>
                    </div>

                    <div id="inButton">
                        <Button class="kakao-login-btn" @click="kakaoLogin">
                            <img src="../icon/ic_kakao_login.png">
                            카카오 계정으로 이용
                        </Button>
                        
                        <Button href="http://pf.kakao.com/_xexjgJT/"
                            v-bind:style="{
                                border: '1px solid rgba(35, 37, 37, 0.24)'}">
                            이용 문의
                        </Button>
                    </div>
                </template>
            </div>
        </main>
    </div>
</template>

<script>
import Button from '@/styles/StyledButton';

export default {
    name: 'LoginVue',
    data() {
        return {
            loading: false,
        }
    },
    components: {
        Button,
    },
    methods: {
        kakaoLogin() {
            this.loading = true;
            if(process.env.NODE_ENV === 'test') this.$router.replace('/login/kakao');
            else {
                window.location.href = `https://kauth.kakao.com/oauth/authorize?client_id=${process.env.VUE_APP_KAKAO_REST_API_KEY}&redirect_uri=${process.env.VUE_APP_URL}/login/kakao/&response_type=code`;
            }        
        }
    }
}
</script>

<style scoped>

#letterImage {
    height: auto;
    width: 280px;
    margin: auto;
}

.inPanel {
    padding: 0px 16px;
}

.kakao-login-btn {
    height: 52px;
    background: rgb(247, 227, 23);
    margin: 0px 0px 12px;
    color: rgba(0, 0, 0, 0.87);
}

</style>