<template>
    <div id="message">
        <header>
            <p> {{ userInfo }} </p>
            <v-btn
                text
                @click="signOut"> 로그아웃 </v-btn>
        </header>

        <main>
            <!-- <div id="sub-header">
                <div id="info-text">
                    <p>20-10-17 이후 주문 내역은</p>
                    <p>앱에서 실시간 확인 가능해요👀</p>
                </div>
                <div id="app-button">
                    <Button
                        outlined
                        style="background-color: white;
                                border: 1px solid rgba(35, 37, 37, 0.24);"
                    >트웬티 앱 열기</Button>
                </div>
            </div> -->
            <template v-if="loading">
                <div style="text-align: center">
                    <v-overlay :z-index="0" :value="loading">
                        <v-progress-circular
                            indeterminate
                            color="primary"
                            id="loading-progress"
                        ></v-progress-circular>
                    </v-overlay>
                </div>
            </template>
            <template v-else>
                <template v-if="error">
                    error
                </template>
                <template v-else>
                    <template v-if="empty">
                        <div style="text-align: center;margin-top: 20px;">주문 내역이 없습니다.</div>
                    </template>
                    <v-list>
                        <v-list-item v-for="(r, i) in receipt" :key="i" style="height:0px; padding: 32px 16px; border-bottom: 1px solid #F0F0F0;">                            
                            <v-list-item-avatar size="40" rounded style="border: 1px solid #F0F0F0;">
                                <img :src="getProfileImg(r.info.profile)">
                            </v-list-item-avatar>
                            <v-list-item-title v-html="r.name" style="margin-left: 8px; font-size: 14px;"></v-list-item-title> 
                            
                            <v-list-item-action-text style="width: 100px; text-align: right;" @click="goReceipt(r.info.publicId, r.info.profile)">
                                주문 {{ r.size }}건
                            </v-list-item-action-text>
                            <v-list-item-action @click="goReceipt(r.info.publicId, r.info.profile)">
                                <v-btn icon>
                                    <img src="../icon/ic_right.png" style="width: 40px;">
                                </v-btn>
                            </v-list-item-action>
                        </v-list-item>
                    </v-list>
                </template>
            </template>
        </main>
    </div>
</template>

<script>
import firebase from 'firebase';
import login from '@/components/Login/loginAPI';
//import Button from '@/styles/StyledButton';

export default {
    name: 'MessageVue',
    components: {
        //Button,
    },
    data() {
        return {
            header: '',
            loading: true,
            error: false,
            userInfo: '',
            empty: false,
            logout: false,
        }
    },
    async mounted() {
        try {
            await this.getMessage();
            this.loading = false;
        } catch(error) {
            this.error = true;
        }
    },
    methods: {
        async getMessage() {
            let token = this.$route.query.firebaseToken;
            if(token) {
                let r = (await this.$http.post(`${process.env.VUE_APP_BASEURL}/auth/kakao/reauth`, {
                    idToken: token
                }));

                if(r.headers.status === 400) {
                    this.$router.replace('/login');
                    return;
                }

                await login(r.data);
            }
            else {
                let user = await firebase.auth().currentUser;
                if(!user) this.$router.replace('/login');
            }

            let idToken = await firebase.auth().currentUser.getIdToken(true);
            let res = (await this.$http.post(`${process.env.VUE_APP_BASEURL}/receipt/user`, {}, {
                            headers: {
                                authorization: `Bearer ${idToken}`
                            }
                        }));
            if(res.data === 'error') throw new Error();

            let { list, userInfo } = res.data;
            let r = list;
            this.userInfo = userInfo;

            let receipt = [];
            for(let k in r) receipt.push({name: k, t: r[k].timestamp, size: r[k].size, info: r[k].info })
            receipt.sort((a, b) => { return b.t - a.t; });

            if(receipt.length === 0) this.empty = true;

            this.receipt = receipt;
        },
        async signOut() {
            await firebase.auth().signOut();
            this.logout = true;
            this.$router.replace('/');
        },
        getProfileImg(img) {
            return `${process.env.VUE_APP_IMG_URL}/${img}`;
        },
        goReceipt(publicId, img) {
            this.$router.push(`/letter/receipt?id=${publicId}&profile=${img}`);
        }
    }
}
</script>

<style scoped>

#sub-header {
    height: 180px;
    background-color: #F6F6F6;
    padding: 0px 16px 0px 16px;
}

#info-text {
    margin-top: 40px;
    margin-bottom: 20px;
    text-align: center;
}

#profile-img {
    height: 60px;
    width: 60px;
    margin: 10px;
    border-radius: 50%;
}

#loading-progress {
    vertical-align: middle;
    justify-content: center;
}

</style>