<template>
    <div id="home">
        <router-view></router-view>
    </div>
</template>

<script>
export default {
    name: 'HomeVue',
    mounted() {
        this.$router.push('/letter');
    },
}
</script>