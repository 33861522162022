import Home from '@/components/Home/Home';

let homeRouter = {
    path: '/',
    name: 'HomeRouter',
    component: Home,
    meta: {
        requiresAuth: false,
    }
};

export default homeRouter;