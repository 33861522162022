import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import firebase from './plugins/firebase';
import axios from 'axios';

import '@/styles/GlobalStyle';
import vuetify from './plugins/vuetify';

// import VueSession from 'vue-session';
// Vue.use(VueSession);

Vue.prototype.$http = axios;
Vue.config.productionTip = false;

let app;
firebase.auth().onAuthStateChanged(() => {
  if(!app) {
    app = new Vue({
      router,
      vuetify,
      render: h => h(App),
    }).$mount('#app');
  }
});