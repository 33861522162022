import styled from "vue-styled-components";

const StyledButton = styled.button`
  display: flex;
  width: 100%;

  /* height, fontSize, fontWeight는 size 추가 */
  height: ${({ height }) => (height ? height : "48px")};
  font-size: 16px;

  border-radius: 4px;

  padding: 6px 8px 6px 8px;

  align-items: center;
  justify-content: center;
  text-decoration: none;
  vertical-align: middle;

  font-weigth: 400px;
  line-height: 1.75;

  img {
    width: 24px;
    height: auto;
    margin-right: 20px;
  }

`;

export default StyledButton;
