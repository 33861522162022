<template>
    <div id='sellerReceipt'>
        <header style="width: 100%;">
            <template v-if="!loading && !error">
                <p style="text-align: left;">
                    <v-btn icon v-on:click="() => { $router.go(-1) }">
                        <img src="../icon/ic_arrowleft.png"
                            id="left-arrow" 
                        >
                    </v-btn>
                </p>
                <p style="text-align: center;"><img :src="getProfileImg()" id="profile-img"></p>
                <p style="text-align: right;"><v-btn text @click="goProfilePage()">프로필</v-btn></p>
            </template>
        </header>
        <main style="margin: 8px 0px;">
            <template v-if="loading">
                <div style="text-align: center;">
                    <v-overlay :z-index="0" :value="loading">
                        <v-progress-circular
                            indeterminate
                            color="primary"
                            id="loading-progress"
                        ></v-progress-circular>
                    </v-overlay>
                </div>
            </template>
            <template v-else>
                <template v-if="error">
                    <p style="text-align: center;">error</p>
                </template>
                <template v-else>
                    <v-list outlined flat v-for="(r, i) in receipt" :key="i" style="margin: 8px 16px;">
                        <v-list-item>                            
                            <v-list-item-content style="padding: 5px;">
                                <v-list-item-action-text>주문 시각 : {{ r.time }}</v-list-item-action-text>
                                <v-list-item-title>{{ r.marketTitle }}</v-list-item-title>
                                <a id="url-text" :href="`${getReceiptUrl(r.publicId)}?firebaseToken=${token}&app=1`">{{ getReceiptUrl(r.publicId) }}</a>
                                <v-list-item-action style="margin-bottom: 0px;">
                                    <Button outlined 
                                            style="background-color: white;border: 1px solid rgba(35, 37, 37, 0.24);"
                                            @click="goReceipt(r.publicId)"
                                    >주문 내역 보기</Button>
                                </v-list-item-action>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </template>
            </template>
        </main>
    </div>
</template>

<script>
import Button from '@/styles/StyledButton';
import firebase from 'firebase';
import sugar from 'sugar';

export default {
    name: 'SellerReceiptVue',
    data() {
        return {
            loading: true,
            error: false,
        }
    },
    async mounted() {
        try {
            await this.getReceipt();
            this.loading = false;
        } catch(error) {
            this.loading = false;
            this.error = true;
        }
    },
    methods: {
        async getReceipt() {
            let { id, profile } = this.$route.query;
            if(id === undefined || profile === undefined) this.$router.replace('/404');

            let token = await firebase.auth().currentUser.getIdToken(true);
            let r = (await this.$http.post(`${process.env.VUE_APP_BASEURL}/receipt/seller/${id}`, {},
                {
                    headers: {
                        authorization: `Bearer ${token}`
                    }
                })).data;

            if(r === 'error') throw new Error();

            r.sort((a, b) => { return b.timestamp - a.timestamp; });
            for(let i = 0;i < r.length;++i) {
                r[i].time = sugar.Date(r[i].timestamp).format('{yy}-{MM}-{dd} {TT} {HH}:{mm}:{ss}');
                delete r[i].timestamp;
            }

            this.receipt = r;
            this.profile = profile;
            this.id = id;

            this.token = token;
        },
        getReceiptUrl(publicId) {
            return `${process.env.VUE_APP_RECEIPT_URL}/${publicId}`;
        },
        goReceipt(publicId) {
            window.location = `${this.getReceiptUrl(publicId)}?firebaseToken=${this.token}&app=1`;
        },
        getProfileImg() {
            return `${process.env.VUE_APP_IMG_URL}/${this.profile}`;
        },
        goProfilePage() {
            window.location = `${process.env.VUE_APP_PROFILE_URL}/${this.id}?firebaseToken=${this.token}&app=1`;
        },
    },
    components: {
        Button,
    }
}
</script>

<style scoped>

#url-text {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-size: 11px;
    margin-top: 8px;
}

#profile-img {
    border-radius: 50%;
    border: 1px solid #F0F0F0;
    width: 40px;
    height: 40px;
}

#left-arrow {
    width: 40px;
}

#loading-progress {
    vertical-align: middle;
    justify-content: center;
}

</style>