<template>
  <div id="app">
    <MainStyle>
      <router-view></router-view>
    </MainStyle>
  </div>
</template>

<script>
import MainStyle from '@/styles/MainStyle';

export default {
  name: 'App',
  components: {
    MainStyle,
  },
  created() {
    // this.$session.start();
  },
  destroyed() {
    // this.$session.destroy();
  },
  methods: {

  }
}
</script>
