import { injectGlobal } from 'vue-styled-components';
import { palette } from './variables';

injectGlobal`
  html, body {
    width: 100%;
    height: 100%;
    margin: 0;
    background: #FAFAFA;
    color: #343434;

    font-size: 16px;
    line-height: 22px;
    font-family: 'Noto Sans KR', 'sans-serif', "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    // text-align: center;

    /* These are technically the same, but use both */
    overflow-wrap: break-word;
    word-wrap: break-word;

     -ms-word-break: break-all;
    /* This is the dangerous one in WebKit, as it breaks things wherever */
    word-break: break-all;
    /* Instead use this non-standard one: */
    word-break: break-word;

    /* Adds a hyphen where the word breaks, if supported (No Blink) */
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;

    span[data-order-number] {
      color: ${palette.black};
    }

    textarea {
      border: 1px solid ${palette.black24};
      font-size: 16px;
      color: ${palette.black}
      padding: 8px;
      font-family: 'Noto Sans KR';
      font-weight: 400;
    }
  
    #app {
      display: grid;
      grid-template-columns: 100%;
      max-width: 414px;
      margin: auto;
      min-height: 100%;
      align-content:start;

      background: white;
      overflow: hidden;
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.12);

    }
    
     input[name="price"], input[name="stock"], input[name="weight"], input[name="limitAmount"], input[name="minPrice"]{
      text-align: right;
    }
  }
`;
