<template>
    <div id="NotFound">
        페이지를 찾을 수 없습니다 
    </div>
</template>

<script>
export default {
    name: 'NotFound',
    mounted() {
        if(this.$route.path !== '/404')
            this.$router.replace('/404');
    }
}
</script>