<template>
    <div id="letter-vue">
        <router-view></router-view>
    </div>
</template>

<script>
export default {
    name: 'LetterVue',
    data() {
        return {
            drawer: false,
        }
    },
    mounted() {
        this.toReceipt();
    },
    methods: {
        toReceipt() {
            if(this.$route.path != '/letter/message')
                this.$router.replace('/letter/message');
        },
    },
    components: {

    }
}
</script>