import Letter from '@/components/Letter/Letter';
import Message from '@/components/Letter/Message';
import Receipt from '@/components/Letter/Receipt';

let letterRouter = {
    path: '/letter',
    name: 'LetterRouter',
    component: Letter,
    children: [
        {
            path: 'message',
            name: 'Message',
            component: Message,
            meta: { 
                requiresAuth: false,
            },
        },
        {
            path: 'receipt',
            name: 'Receipt',
            component: Receipt,
            meta: {
                requiresAuth: true,
            },
        }
    ]
}

export default letterRouter;