<template>
    <div id="kakaoLogin">
        <v-overlay :z-index="0" :value="loading">
            <v-progress-circular
                indeterminate
                color="primary"
                id="loading-progress"
            ></v-progress-circular>
        </v-overlay>
    </div>
</template>

<script>
import login from '@/components/Login/loginAPI';

export default {
    name: 'KakaoLoginVue',
    beforeCreate() {
        this.$emit('login');
    },
    async mounted() {
        try {
            await this.kakaoAuth();
            this.$router.replace('/letter');
        } catch(error) {
            alert('로그인 실패: 다시 시도해주시기 바랍니다');
            this.$router.replace('/');
        }
    },
    methods: {
        async kakaoAuth() {
            if(process.env.NODE_ENV === 'test') {
                let firebaseToken = (await this.$http.post(`${process.env.VUE_APP_BASEURL}/auth/kakao`, {
                    accessToken: 'WMgI7Kh_7FVB_tH4i90Lk7iapeDkkZJPR9XdCwo9c5oAAAF3zgGzzw'
                }, {})).data;

                await login(firebaseToken);
                return;
            }

            // get kakao code
            let code = this.$route.query.code;
            if(code === undefined) throw new Error('no access code');

            let accessToken = (await this.$http.post(`https://kauth.kakao.com/oauth/token`, null, {
                params: {
                    grant_type: 'authorization_code',
                    client_id: process.env.VUE_APP_KAKAO_REST_API_KEY,
                    redirect_uri: process.env.VUE_APP_URL + '/login/kakao/',
                    code
                }
            })).data.access_token;

            let firebaseToken = (await this.$http.post(`${process.env.VUE_APP_BASEURL}/auth/kakao`, {
                accessToken
            }, {})).data;

            await login(firebaseToken);
        }
    }
}
</script>

<style scoped>

#loading-progress {
    position: relative;
    vertical-align: middle;
    justify-content: center;
}

</style>