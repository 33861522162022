// It is better to use singular forms for keys.
// In `bind` mode keys are used to set fallbacks
// so `color` is better than `colors` as a prop.
// cf. https://www.npmjs.com/package/styled-props

//import { rgba } from 'polished';

export const palette = {
  none: 'none',
  black: '#2D3330',
  black12: '#DDDEDE',
  black24: '#CDCECD',
  black40: '#ABADAC',
  white: '#ffffff',
  lightgray: '#f6f6f6',
  green: '#45C295',
  green12: '#E6F7F3',
  red: '#E96B6B',
  yellow: '#F5AF26',
  blue: '#3F729B',
  kakao: '#F7E317',
  kakaoDark: '#3C1E1E',
};

export const background = {
  default: palette.white,
  defaultText: palette.none,
  option: palette.lightgray,

  primary: palette.green,
  primaryText: palette.none,

  secondary: palette.black,

  caution: '#FADBDB',
  cautionText: palette.none,

  disabled: palette.black40,
  readOnly: palette.lightgray,
  hide: palette.black12,
  kakao: palette.kakao,
};

export const color = {
  default: palette.black,
  defaultText: palette.black,
  option: palette.black,

  primary: palette.white,
  primaryText: palette.green,

  secondary: palette.white,

  caution: '#E96B6B',
  cautionText: palette.red,

  disabled: palette.white,
};

export const borderColor = {
  default: palette.black24,
  defaultText: palette.none,
  option: palette.black24,

  primary: palette.green,
  primaryText: palette.none,

  secondary: palette.black,

  caution: 'none',
  cautionText: palette.none,

  disabled: palette.black40,
  kakao: 'none',
};

export const size = {};
