import Vue from 'vue';
import Router from 'vue-router';
import Home from './Home/home';
import User from './Letter/letter';
import Login from './Login/login';
import NotFound from '@/components/NotFound';

import firebase from 'firebase';

Vue.use(Router);

let router = new Router({
    routes: [
        Home,
        User,
        Login,
        {
            path: '*',
            component: NotFound,
        },
        {
            path: '/404',
            component: NotFound,
        }
    ],
    mode: 'history'
});

router.beforeEach(async (to, from, next) => {
    let currentUser = await firebase.auth().currentUser;
    let requiresAuth = to.matched.some(r => r.meta.requiresAuth);

    if(requiresAuth && !currentUser) {
        next({ path: '/login', next: to.path });
    }
    else next();
});

export default router;